import React, { useCallback, useState, useEffect } from "react";
import {
  Image,
  Platform,
  ScrollView,
  StyleSheet,
  Text,
  Pressable,
  View,
  Dimensions,
  PixelRatio,
} from "react-native";
import StyledTextOther from "./StyledTextOther";
import { Link } from "react-router-dom";
import { useParams, useLocation } from "react-router-dom";
import Flex from "../components/common/Flex";
import { Box, Input } from "../components/common/";
import {
  FontAwesome5,
  FontAwesome,
  MaterialCommunityIcons,
  MaterialIcons,
  Octicons,
} from "@expo/vector-icons";
import Colors from "../theme/Colors";

// import pkg from "expo/package.json";
const pkg = require("../../app.json");
export function MenuCont({
  onMenuIconPress,
  userSecurity,
  onPress,
  width,
  isDefault,
}) {
  let location = useLocation();
  const [hasPavog, setPavog] = useState(false);
  const [hasPrico, setPreco] = useState(false);
  const [hasBVStudy, setBVStudy] = useState(false);
  useEffect(() => {
    let hasPavog = userSecurity.some((el) => el.PermissionName === "App:PaVoG");
    let hasPrico = userSecurity.some((el) => el.PermissionName === "App:Prico");
    let hasBVStudy = userSecurity.some(
      (el) => el.PermissionName === "App:BVStudy"
    );

    setPavog(hasPavog);
    setPreco(hasPrico);
    setBVStudy(hasBVStudy);
  }, []);
  let props = {};

  return (
    <View style={width <= 892 && styles.overlay}>
      <View style={[styles.menuContainer, { flexDirection: "column" }]}>
        <View style={{ flex: 1 }}>
          <Flex alignCenter>
            <View>
              <Pressable onPress={onMenuIconPress}>
                <Image
                  resizeMode="contain"
                  source={require("../../assets/logo/IRISNEW2.png")}
                  style={{
                    width: 180,
                    height: 80,
                  }}
                />
              </Pressable>
            </View>
          </Flex>
        </View>
        <View style={{ flex: 8 }}>
          <View
            style={{
              flex: 20,
              flexShrink: 0,
              flexBasis: "auto",
              shadowColor: "#000",
              backgroundColor: Colors.twentyPurple,
              borderBottomLeftRadius: 0,
              borderBottomRightRadius: 0,
              borderTopLeftRadius: 10,
              borderTopRightRadius: 10,
            }}
          >
            <View>
              <View style={[styles.menuItem, styles.menuHeaderContainer]}>
                <StyledTextOther
                  style={[
                    styles.menuItemText,
                    styles.bold,
                    styles.menuItemsHeader,
                    { color: Colors.irisPurple },
                  ]}
                >
                  PERSONAL
                </StyledTextOther>
              </View>
              <View style={[styles.subMenuItem]}>
                <MaterialCommunityIcons
                  name="cards-heart"
                  color="red"
                  backgroundColor="#FFFFFF"
                  style={styles.iconStyle}
                  size={20}
                />
                <Text style={styles.menuItemText}>Saved Reports</Text>
              </View>
            </View>
            <View style={[styles.menuItem, styles.menuHeaderContainer]}>
              <StyledTextOther
                style={[
                  styles.menuItemText,
                  styles.bold,
                  styles.menuItemsHeader,
                  { color: Colors.irisPurple },
                ]}
              >
                ADMIN
              </StyledTextOther>
            </View>
            <View
              style={[
                styles.subMenuItem,
                location.pathname === "/" && styles.selectedStreamListItem,
              ]}
            >
              <MaterialIcons
                name="settings"
                color={Colors.royalBlue}
                backgroundColor="#FFFFFF"
                style={styles.iconStyle}
                size={23}
              />
              <Link
                to="/"
                style={{
                  textDecoration: "none",
                  color: "#FFFFFF",
                }}
              >
                <Text
                  style={[
                    styles.menuItemText,
                    location.pathname === "/" &&
                      styles.selectedStreamListItemText,
                  ]}
                >
                  Configuration
                </Text>
              </Link>
            </View>
            <View
              style={[
                styles.subMenuItem,
                location.pathname === "/" && styles.selectedStreamListItem,
              ]}
            >
              <MaterialCommunityIcons
                name="lock"
                color={Colors.royalBlue}
                backgroundColor="#FFFFFF"
                style={styles.iconStyle}
                size={25}
              />
              <Link
                to="/"
                style={{
                  textDecoration: "none",
                  color: "#FFFFFF",
                }}
              >
                <Text
                  style={[
                    styles.menuItemText,
                    location.pathname === "/" &&
                      styles.selectedStreamListItemText,
                  ]}
                >
                  Security
                </Text>
              </Link>
            </View>

            <View style={[styles.menuItem, styles.menuHeaderContainer]}>
              <StyledTextOther
                style={[
                  styles.menuItemText,
                  styles.bold,
                  styles.menuItemsHeader,
                  { color: Colors.irisPurple },
                ]}
              >
                CLINICAL STUDY
              </StyledTextOther>
            </View>

            {hasPavog && (
              <View
                style={[
                  styles.subMenuItem,
                  location.pathname === "/PaVog" &&
                    styles.selectedStreamListItem,
                ]}
              >
                <MaterialCommunityIcons
                  name="alpha-p-box"
                  color={Colors.royalBlue}
                  backgroundColor="#FFFFFF"
                  style={styles.iconStyle}
                  size={25}
                />

                <Link
                  onClick={onPress}
                  to="/PaVog"
                  style={{
                    textDecoration: "none",
                    color: "#FFFFFF",
                  }}
                >
                  <Text
                    style={[
                      styles.menuItemText,
                      location.pathname === "/PaVog" &&
                        styles.selectedStreamListItemText,
                    ]}
                  >
                    PaVog
                  </Text>
                </Link>
              </View>
            )}
            {hasBVStudy && (
              <View
                style={[
                  styles.subMenuItem,
                  location.pathname === "/BvStudy" &&
                    styles.selectedStreamListItem,
                ]}
              >
                <MaterialCommunityIcons
                  name="alpha-b-box"
                  color={Colors.royalBlue}
                  backgroundColor="#FFFFFF"
                  style={[styles.iconStyle, { marginLeft: "1%" }]}
                  size={25}
                />

                <Link
                  onClick={onPress}
                  to="/BvStudy"
                  style={{
                    textDecoration: "none",
                    color: "#FFFFFF",
                  }}
                >
                  <Text
                    style={[
                      styles.menuItemText,
                      location.pathname === "/BvStudy" &&
                        styles.selectedStreamListItemText,
                    ]}
                  >
                    BV Study
                  </Text>
                </Link>
              </View>
            )}
            {hasPrico && (
              <View
                style={[
                  styles.subMenuItem,
                  location.pathname === "/fileupload" &&
                    styles.selectedStreamListItem,
                ]}
              >
                <FontAwesome5
                  name="book-reader"
                  color={Colors.royalBlue}
                  backgroundColor="#FFFFFF"
                  style={styles.iconStyle}
                  size={25}
                />
                <Link
                  onClick={onPress}
                  to="/fileupload"
                  style={{
                    textDecoration: "none",
                    color: "#FFFFFF",
                  }}
                >
                  <Text
                    style={[
                      styles.menuItemText,
                      location.pathname === "/fileupload" &&
                        styles.selectedStreamListItemText,
                    ]}
                  >
                    PRECO Study
                  </Text>
                </Link>
              </View>
            )}
          </View>

          {/* --- 
                    Vyaire footer logo here . 
                    Add new menu items on the top view block 
                    ---- */}
          <View
            style={{
              flex: 1,
              flexShrink: 0,
              flexBasis: "auto",
              flexDirection: "column-reverse",
              shadowColor: "#000",
              backgroundColor: Colors.twentyPurple,
              borderBottomLeftRadius: 10,
              borderBottomRightRadius: 10,
              borderTopLeftRadius: 0,
              borderTopRightRadius: 0,
              alignItems: "center",
              aligntSelf: "center",
              marginBottom: "5%",
            }}
          >
            <View>
              <Image
                resizeMode="contain"
                style={{
                  width: 70,
                  height: 20,
                }}
                source={require("../../assets/icons/blueLogo.png")}
              />
              <Text
                style={{ alignSelf: "center", color: Colors.irisPurple }}
                fontSize="11px"
              >
                {pkg.expo.version}
              </Text>
            </View>
          </View>
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  bold: { fontWeight: "bold" },
  overlay: {
    right: 0,
    left: 0,
    top: 0,
    bottom: 0,
    position: "absolute",
    backgroundColor: "white",
    zIndex: 1,
    width: "100%",
  },
  menuContainer: {
    minHeight: "-webkit-fill-available",
    backgroundColor: "#FFFFFF",
    // width: 120,
    shadowColor: "#000",
    shadowOffset: {
      width: 1,
      height: 0,
    },
    shadowOpacity: 0.18,
    shadowRadius: 1.0,
    elevation: 1,
    borderRightWidth: "1px",
    borderRightColor: Colors.irisPurple,
    borderRadius: "10px",
    alignItems: "center",
  },
  menuItem: {
    flexDirection: "row",
    alignItems: "center",
    alignSelf: "self-start",
    paddingLeft: 10,
    paddingRight: 15,
    marginLeft: "-5%",
  },
  subMenuItem: {
    flexDirection: "row",
    alignItems: "center",
    paddingLeft: 10,
    paddingRight: 15,
  },
  menuItemText: {
    fontSize: 14,
    color: Colors.royalBlue,
    fontWeight: "normal",
  },
  menuItemTextOnHover: {
    fontSize: 14,
    fontWeight: "normal",
    backgroundImage: "linear-gradient(to right, #eb003b 15%, #02008a 93%)",
    backgroundSize: "100%",
  },
  menuIHItemText: {
    fontSize: 14,
    color: Colors.royalBlue,
    fontWeight: "normal",
    paddingLeft: "2%",
  },
  menuItemDSText: {
    fontSize: 14,
    color: Colors.royalBlue,
    fontWeight: "normal",
    paddingLeft: "2%",
  },
  menuHeaderContainer: {
    marginTop: 20,
    marginBottom: 10,
    marginHorizontal: 25,
  },
  menuItemsHeader: {
    borderRadius: 25,
    color: "#FFFFFF",
    paddingVertical: 5,
    paddingHorizontal: 25,
  },
  menuItemIcon: {
    width: 35,
    height: 35,
    paddingRight: 5,
  },
  selectedStreamListItem: {
    borderLeftColor: "#d6e8ff",
    borderLeftWidth: 3,
    borderRadius: 2,
  },
  selectedStreamListItemText: {
    // color: '#E57230',
    color: "red",
  },
  streamIconStyle: {
    width: 28,
    height: 28,
    paddingRight: 5,
  },
  scrollView: {
    flex: 8,
    flexBasis: "auto",
    flexGrow: "inherit",
  },
  innerItemsHeader: {
    backgroundColor: Colors.royalBlue,
    borderRadius: 25,
    color: "#FFFFFF",
    marginBottom: 10,
    paddingVertical: 5,
    paddingHorizontal: 25,
  },
  reportMenuContainer: {
    //width: 270,
    borderRightWidth: 1,
    borderRightColor: "grey",
    marginTop: 20,
    marginBottom: 10,
  },
  iconStyle: {
    padding: 6,
    paddingVertical: 4,
  },
  logoContainer: {
    padding: 10,
    flexDirection: "row",
    alignItems: "center",
  },
  vyaireLogo: {
    width: 250,
    height: 65,
  },
});
