import React, { Component } from "react";
import {
  StyleSheet,
  View,
  Image,
  ActivityIndicator,
  Dimensions,
} from "react-native";

import { connect } from "react-redux";
import { authUser, handleSignOut } from "./appRedux/actions/Auth";
import Menu from "./components/Menu";
import Header from "./components/header";
import Routes from "./navigation/Routes";
import { Router, Route, Switch, Redirect, Link } from "./navigation/router";
import { MenuContext } from "./components/MenuContext";
import { withRouter } from "react-router-dom";
import * as Font from "expo-font";

class SourceApp extends Component {
  toggle = (value = null) => {
    const isBoolean = typeof value === "boolean";
    this.setState((state) => ({
      isToggled: isBoolean ? value : !state.isToggled,
    }));
  };

  constructor(props) {
    super(props);

    this.toggleMenu = () => {
      this.setState((state) => ({
        isToggled: !state.isToggled,
      }));
    };

    this.state = {
      isToggled: false,
      toggleMenu: this.toggle,
      redirect: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.location !== prevProps.location &&
      this.state.isToggled === true &&
      this.props.location.pathname !== "/search"
    ) {
      this.toggle(false);
    }
  }

  async componentDidMount() {
    try {
      // await Image.prefetch(require("../assets/icons/fabian3.png"));
      // await Image.prefetch(require("../assets/icons/bv-final.png"));
      await Font.loadAsync({
        "helvetica-neue-thin": require("../assets/fonts/helvetica/HelveticaNeue-Thin.ttf"),
      });
      this.setState({ fontLoaded: true });
    } catch (error) {
      console.error("Error prefetching images:", error);
    }
    // setTimeout(() => {
    //   this.setState({ fontLoaded: true });
    // }, 500);
  }

  render() {
    const { isToggled, redirect } = this.state;
    const { match } = this.props;
    const { location } = this.props;
    const windowHeight = Dimensions.get("window").height;

    return (
      <View style={styles.container}>
        <MenuContext.Provider value={this.state}>
          {location && location.pathname !== "/" && (
            <View
              style={[isToggled ? { flex: 2.5 } : { flex: 1 }, { zIndex: 3 }]}
            >
              <Menu
                onMenuIconPress={() => this.toggle(!isToggled)}
                style={{
                  justifyContent: "space-around",
                  position: "absolute",
                  zIndex: 1,
                  width: "25%",
                }}
                isToggled={isToggled}
              />
            </View>
          )}
          <View
            style={[
              isToggled ? { flex: 12 } : { flex: 18 },
              { height: windowHeight },
            ]}
          >
            <View
              style={{
                flexDirection: "column",
                flexBasis: "auto",
                flexGrow: "inherit",
              }}
            >
              <View style={{ flex: 1 }}>
                <Header
                  style={{ position: "absolute", zIndex: 1 }}
                  currentUser={this.props.user}
                  onLogout={this.props.handleSignOut}
                />
              </View>

              {this.state.fontLoaded ? (
                <View style={{ flex: 8 }}>
                  <Routes match={match} />
                  {redirect ? <Redirect to="/" /> : null}
                </View>
              ) : (
                <ActivityIndicator size="large" />
              )}
            </View>
          </View>
        </MenuContext.Provider>
      </View>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loggedIn: state.auth.loggedIn,
    user: state.auth.user,
    loggedOut: state.auth.loggedOut,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    authUser,
    handleSignOut,
  })(SourceApp)
);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    height: "100vh",
    flexDirection: "row",
    backgroundColor: "white",
  },
});
