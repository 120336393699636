import React, { useCallback, useState } from "react";
// import { Auth } from 'aws-amplify';
import {
  Image,
  Platform,
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  Pressable,
  View,
  Dimensions,
  PixelRatio,
  Animated,
  Easing,
} from "react-native";

// import pkg from "expo/package.json";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import { Box, Text as StyledText } from "../components/common";

// import { Pressable  } from "react-native-web";
import useAnimation from "./useAnimation";
// import { MenuContext } from "../Root";
import { MenuContext } from "./MenuContext";

import { MenuCont } from "./MenuCont";
import { DockMenuCont } from "./DockMenuCont";
const pkg = require("../../app.json");
const { timing } = Animated;

const MenuItem = ({
  children,
  href,
  target,
  color = "#10254D",
  hideIcon = false,
  fontSize = 16,
  onClick,
}) => {
  const textStyle = [styles.menuItemText, { color, fontSize }];

  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    if (!isAdmin) {
      getIsAdmin();
    }
  }, []);

  const getIsAdmin = async () => {
    // Auth.currentSession()
    //     .then((res) => {
    //         let accessToken = res.getAccessToken();
    //         let payload = accessToken.payload;
    //         console.log('auth payload::::::', payload);
    //         console.log('---ful res---', res);
    //         let cognitoGroups = payload[COGNITO_GROUPS];
    //     })
    //     .catch((e) => {
    //         log.error('Error log: ', e);
    //         return e;
    //     });
  };

  return (
    <View style={styles.menuItem}>
      {target ? (
        <Link
          to="route"
          target="_blank"
          onClick={(event) => {
            event.preventDefault();
            window.open(href);
          }}
        >
          <View
            style={{
              flex: 1,
              flexDirection: "row",
            }}
          >
            {!hideIcon && (
              <Image
                resizeMode="contain"
                source={require("../../assets/icons/arrow.png")}
                style={{
                  flex: 1,
                  flexBasis: "auto",
                  width: 10,
                  height: 15,
                  marginRight: 9,
                }}
              />
            )}
            <Text style={textStyle}>{children}</Text>
          </View>
        </Link>
      ) : (
        <Link to={href}>
          <View
            style={{
              flex: 1,
              flexDirection: "row",
            }}
          >
            {!hideIcon && (
              <Image
                resizeMode="contain"
                source={require("../../assets/icons/arrow.png")}
                style={{
                  flex: 1,
                  flexBasis: "auto",
                  width: 10,
                  height: 15,
                  marginRight: 9,
                }}
              />
            )}
            <Text style={textStyle}>{children}</Text>
          </View>
        </Link>
      )}
    </View>
  );
};

const SubMenu = ({ children, title }) => (
  <>
    <View style={styles.menuHeaderContainer}>
      <Text style={[styles.menuItemText, styles.bold, styles.menuItemsHeader]}>
        {title}
      </Text>
    </View>
    {children}
  </>
);

export function MenuContent({ width, height, isToggled, toggleMenu }) {
  return (
    <Pressable
      onPress={() => toggleMenu(false)}
      style={{ cursor: "none" }}
      pointerEvents={!isToggled ? "none" : "box-none"}
    >
      <View
        style={[styles.overlay, { width: width }]}
        pointerEvents={!isToggled ? "none" : "box-none"}
      >
        <View style={{ height: "100%" }}>
          <View
            style={[
              styles.menuContainer,
              width <= 892 && {
                zIndex: -1,
                position: "absolute",
                width: 245,
              },
            ]}
          >
            <ScrollView
              style={{
                flex: 1,
              }}
              keyboardShouldPersistTaps="always"
            >
              <SubMenu title="KPI">
                <MenuItem>Reports & Dashboards</MenuItem>
                <Box style={{ paddingLeft: 25 }}>
                  <MenuItem
                    hideIcon
                    href="https://insight.vyaire.com/#streams/Customer%20Master"
                    fontSize={15}
                    target="_blank"
                  >
                    Customer Master
                  </MenuItem>
                  <MenuItem
                    hideIcon
                    href="https://insight.vyaire.com/#streams/Material%20Master"
                    fontSize={15}
                    target="_blank"
                  >
                    Material Master
                  </MenuItem>
                </Box>
              </SubMenu>

              <SubMenu title="DATA GOVERANCE">
                <MenuItem href="/material/new" color="grey">
                  {`Council\n(Coming Soon)`}
                </MenuItem>
                <MenuItem href="/material/update" color="grey">
                  {`Policies\n(Coming Soon)`}
                </MenuItem>
                <MenuItem href="/material/decommission" color="grey">
                  {`Standards\n(Coming Soon)`}
                </MenuItem>
              </SubMenu>

              <SubMenu title="HELP">
                <MenuItem
                  href="https://servicedesk.vyaire.com/app/itdesk/HomePage.do"
                  target="_blank"
                >
                  Connect It
                </MenuItem>

                <View style={[styles.menuItem, { borderBottomWidth: 0 }]}>
                  <Link to={"/"}>
                    <View
                      style={{
                        flex: 1,
                        flexDirection: "row",
                      }}
                    >
                      <Image
                        resizeMode="contain"
                        source={require("../../assets/icons/arrow.png")}
                        style={{
                          flex: 1,
                          flexBasis: "auto",
                          width: 10,
                          height: 15,
                          marginRight: 9,
                        }}
                      />

                      <Text style={[styles.menuItemText, { color: "grey" }]}>
                        {`Contact Us\n(Coming Soon)`}
                      </Text>
                    </View>
                  </Link>
                </View>
              </SubMenu>
              <Box display="flex" flexDirection="row" flex="1">
                <Box ml="auto" pr="25px">
                  <StyledText fontSize="15px">{pkg.expo.version}</StyledText>
                </Box>
              </Box>
            </ScrollView>
          </View>
        </View>
      </View>
    </Pressable>
  );
}

const AnimatedComponent = ({ doAnimation, children }) => {
  const animation = useAnimation({
    doAnimation,
    duration: 150,
    easing: Easing.ease,
    type: "timing",
  });
  return (
    <Animated.View
      pointerEvents={doAnimation ? "auto" : "none"}
      style={{
        zIndex: 3,
        position: "absolute",
        transform: [
          {
            translateX: animation.interpolate({
              inputRange: [0, 1],
              outputRange: [-200, 0],
            }),
          },
        ],
        opacity: animation,
      }}
    >
      {children}
    </Animated.View>
  );
};

class Menu extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const windowHeight = Dimensions.get("window").height;

    return this.props.isToggled ? (
      <MenuContext.Consumer>
        {({ isToggled, toggleMenu }) => {
          return (
            <MenuCont
              onMenuIconPress={this.props.onMenuIconPress}
              userSecurity={this.props.userRolePermissions || []}
              {...{
                ...this.props,
                width: 240,
                height: windowHeight,
              }}
            />
          );
        }}
      </MenuContext.Consumer>
    ) : (
      <>
        <DockMenuCont
          onMenuIconPress={this.props.onMenuIconPress}
          userSecurity={this.props.userRolePermissions}
          {...{
            ...this.props,
            width: 80,
            height: windowHeight,
          }}
        />
      </>
    );
  }
}

const { width: SCREEN_WIDTH, height: SCREEN_HEIGHT } = Dimensions.get("window");
const mapStateToProps = ({ auth }) => {
  const { userRolePermissions } = auth;
  return {
    userRolePermissions,
  };
};
export default connect(mapStateToProps, {})(Menu);

// based on iphone 5s's scale
const scale = SCREEN_WIDTH / 320;

export function normalize(size) {
  const newSize = size * scale;
  if (Platform.OS === "ios") {
    return Math.round(PixelRatio.roundToNearestPixel(newSize));
  }

  return Math.round(PixelRatio.roundToNearestPixel(newSize)) - 2;
}

const styles = StyleSheet.create({
  bold: { color: "#10254D", fontFamily: "Poppins", fontWeight: "700" },
  logoContainer: {
    padding: 10,
    flexDirection: "row",
    alignItems: "center",
    borderBottomColor: "#cfcfcf",
    borderBottomWidth: 1,
  },
  overlay: {
    zIndex: 1,
    height: "100%",
  },
  menuContainer: {
    height: "100%",
    backgroundColor: "#FFFFFF",
    width: 300,
    paddingBottom: 20,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.23,
    shadowRadius: 2.62,

    elevation: 4,
  },
  menuItem: {
    flexDirection: "row",
    alignItems: "center",
    borderBottomColor: "#CCE7F2",
    borderBottomWidth: 1,
    borderBottomStyle: "solid",
    paddingBottom: 16,
    paddingRight: 15,
    marginTop: 16,
    marginHorizontal: 32,
  },
  menuItemText: {
    fontSize: 16,
    color: "#10254D",
    fontFamily: "Poppins",
    fontWeight: "400",
  },
  menuHeaderContainer: {
    paddingLeft: 32,
    backgroundColor: "#D0E8F2",
    paddingVertical: 12,
  },
  menuItemsHeader: {
    color: "#10254D",
    fontFamily: "Poppins",
    fontSize: 17,
  },
  menuItemIcon: {
    width: 35,
    height: 35,
    paddingRight: 5,
  },
  selectedStreamListItem: {
    borderLeftColor: "#6ED2F6",
    borderLeftWidth: 3,
    borderRadius: 2,
  },
  selectedStreamListItemText: {
    color: "#E57230",
  },
});
