import {
  SEARCH_PATIENTS,
  SEARCH_PATIENTS_SUCCESS,
  SEARCH_PATIENTS_FAIL,
  FETCH_VG_STUDY,
  FETCH_VG_STUDY_SUCCESS,
  REFRESH_VG_REPORT_STATUS,
  REFRESH_VG_REPORT_STATUS_SUCCESS,
  FORCE_REFRESH_VG_STUDY_SUCCESS,
} from "../../constants/ActionTypes";

export const initialState = {
  fetching: false,
  fetchingVGReport: false,
  fetchingReportStatus: false,
  patientSearchList: [],
  patientsVgStudy: [],
  reportStatuses: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SEARCH_PATIENTS:
      return {
        ...state,
        fetching: true,
      };
    case SEARCH_PATIENTS_SUCCESS:
      return {
        ...state,
        fetching: false,
        patientSearchList: action.payload,
      };
    case SEARCH_PATIENTS_FAIL:
      return {
        ...state,
        fetching: false,
        patientSearchList: [],
      };
    case FETCH_VG_STUDY:
      return {
        ...state,
        fetchingVGReport: true,
        patientsVgStudy: [],
      };
    case FETCH_VG_STUDY_SUCCESS:
      console.log("FETCH_VG_STUDY_SUCCESS action.payload", action);
      return {
        ...state,
        fetchingVGReport: false,
        patientsVgStudy: action.payload,
      };
    case REFRESH_VG_REPORT_STATUS:
      return {
        ...state,
        fetchingReportStatus: true,
        reportStatuses: [],
      };
    case REFRESH_VG_REPORT_STATUS_SUCCESS:
      let reports = [...state.patientsVgStudy, ...action.payload];
      let uniqReports = Array.from(
        new Map(reports.map((e) => [e.PatientId, e])).values()
      );

      return {
        ...state,
        fetchingVGReport: false,
        fetchingReportStatus: false,
        patientsVgStudy: [...uniqReports],
      };
    case FORCE_REFRESH_VG_STUDY_SUCCESS:
      let p_report = [...state.patientsVgStudy, ...action.payload];
      let p_uniqReports = Array.from(
        new Map(p_report.map((e) => [e.PatientId, e])).values()
      );

      return {
        ...state,
        fetching: false,
        patientsVgStudy: [...p_uniqReports],
      };

    default:
      return state;
  }
};
