import React from "react";
import IRISApp from "./src/Root";
import { Provider } from "react-redux";
import createStore from "./src/appRedux/store";
import { View } from "react-native";

const store = createStore();

export default function App() {
  return (
    <View style={{ flex: 1, flexBasis: "auto", height: "100%" }}>
      <Provider store={store}>
        <IRISApp />
      </Provider>
    </View>
  );
}
