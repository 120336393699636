import {
  all,
  call,
  fork,
  put,
  takeEvery,
  takeLatest,
} from "redux-saga/effects";
import {
  authUserSuccess,
  authUserFailed,
  authUserPermissions,
  logoutUserSuccess,
  logoutUserFailed,
} from "../actions/Auth";
import { Auth } from "aws-amplify";
import { AUTH_USER, LOGOUT_USER } from "../../constants/ActionTypes";
import { ajaxPostRequest, endpoints } from "./config";
import AsyncStorage from "@react-native-async-storage/async-storage";

const capitalize = (s) => {
  if (typeof s !== "string") return "";
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export function* authUser(action) {
  try {
    const user = yield call([Auth, "currentAuthenticatedUser"], {
      bypassCache: true,
    });

    const sessionData = yield call([Auth, "currentSession"]);
    const userAttributes = user.attributes;
    const fullName = userAttributes.email.slice(0, -11).split(".");
    const username =
      capitalize(fullName[0]) +
      (fullName[1] ? " " + capitalize(fullName[1]) : "");
    const userId =
      fullName[0].toLowerCase() +
      (fullName[1] ? "." + fullName[1].toLowerCase() : "");

    // Use AsyncStorage if running in React Native
    window.localStorage.setItem("userId", userId);

    const userData = {
      ...user,
      username,
    };

    yield put(authUserSuccess(userData));

    let idToken = sessionData.getIdToken();
    let payload = idToken.payload;
    let irisSecurityPermissions = JSON.parse(
      payload["IRISSecurityPermissions"] || "[]"
    );

    window.localStorage.setItem(
      "irisPer",
      payload["IRISSecurityPermissions"] || "[]"
    );

    yield put(authUserPermissions(irisSecurityPermissions));

    yield* getTeamInfo({ UserId: userId });
  } catch (error) {
    console.log("Error fetching user or session:", error);
    yield put(authUserFailed("error"));
  }
}

export function* getTeamInfo(data) {
  const url = endpoints.getUserTeam;
  try {
    let teamIds = [];
    const result = yield call(ajaxPostRequest, url, data);
    if (result.IsSuccess) {
      teamIds = result.ResultData.TeamIds;
    } else {
      //users not in CM system , users in AD group
      teamIds = [99];
    }
    localStorage.setItem("teamIds", JSON.stringify(teamIds));
  } catch (error) {
    console.log("team info not found error", error);
  }
}

// Saga to handle user logout
export function* handleSignOut() {
  try {
    // Clear AsyncStorage (for mobile) or session
    yield call(AsyncStorage.clear);
    yield call(Auth.signOut);

    yield put(logoutUserSuccess());
  } catch (error) {
    console.error("Logout failed", error);
    yield put(logoutUserFailed(error));
  }
}

const authSagas = function* rootSaga() {
  yield all([takeLatest(AUTH_USER, authUser)]);
  yield all([takeLatest(LOGOUT_USER, handleSignOut)]);
};
export default authSagas;
