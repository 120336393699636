import {
  LOGOUT,
  AUTH_USER,
  AUTH_USER_SUCCESS,
  AUTH_USER_FAILED,
  LOGGED_IN_STATUS_CHANGED,
  LOGOUT_USER,
  USER_ROLE_PERMISSIONS,
  LOGOUT_USER_SUCCESS,
  LOGOUT_USER_FAILED,
} from "../../constants/ActionTypes";

export const initialState = {
  error: null,
  loading: false,
  loggedIn: null,
  user: null,
  loggedOut: null,
  userRolePermissions: [],
};

/* ------------- Selectors ------------- */

export default (state = initialState, action) => {
  switch (action.type) {
    case AUTH_USER:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case AUTH_USER_SUCCESS:
      return {
        ...state,
        user: action.user,
        loading: false,
        loggedIn: true,
      };
    case AUTH_USER_FAILED:
      return {
        ...state,
        error: action.error || "Authentication Failed",
        password: "",
        loading: false,
        loggedIn: false,
      };
    case LOGOUT_USER_SUCCESS:
      return { ...state, loading: false, user: null, loggedIn: false };
    case LOGOUT_USER_FAILED:
      return { ...state, loading: false, error: action.error };
    case USER_ROLE_PERMISSIONS:
      return {
        ...state,
        userRolePermissions: action.userolePermissions,
      };
    case LOGOUT:
      return initialState;
    case LOGGED_IN_STATUS_CHANGED:
      return {
        ...state,
        loggedIn: action.loggedIn,
      };
    case LOGOUT_USER:
      return {
        ...state,
        loggedOut: action.loggedOut,
        loggedIn: action.loggedIn,
      };
    default:
      return state;
  }
};
