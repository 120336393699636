import styled from "styled-components";
import { TextInput, Text } from "react-native";
import { COMMON, BORDER, FLEX, get, LAYOUT, variant } from "../constants";

const sizeVariants = variant({
  variants: {
    solid: {
      borderWidth: 1,
      borderRadius: 2.5,
      borderColor: "#EDEDED",
      background: "#FFF",
    },
    outline: {
      borderBottomWidth: "thin",
      borderColor: "#6e6e6e",
      boxShadow: "none",
      backgroundColor: "transparent",
      px: 2,
    },
    outlineValue: {
      borderBottomWidth: "none",
      borderColor: "#6e6e6e",
      boxShadow: "none",
      px: 2,
    },
  },
});

const Input = styled(TextInput).attrs((props) => ({
  type: props.type || "text",
}))`
    display: inline-block;
    box-sizing: border-box;
    background-color: transparent;
    box-shadow: ${get("shadows.formControl")};
    min-width: ${(props) =>
      props.stringLength > 30
        ? "350px"
        : props.stringLength > 25
        ? "300px"
        : props.stringLength > 20
        ? "250px"
        : "200px"};
    width: ${(props) => (props.small ? "70%" : "100%")};
    font-weight: 500;
    font-size: ${(props) => (props.small ? "12px" : "inherit")};
    color: ${(props) => (props.sfdcdisabled ? "#AAAAAA" : "inherit")};
    vertical-align: middle;
    opacity: 1;

    padding: ${(props) =>
      props.inline
        ? "0.25rem 0.25rem 0.2rem 0.75rem"
        : "0.325rem 0.25rem 0.3rem 0.75rem"}
    padding: ${(props) => props.disabled && !props.disabledPadding && "0px"}
    margin-top: ${(props) => (props.inline ? 0 : "0.125rem")};
    margin-bottom: ${(props) => (props.inline ? "0.5rem" : "0.125rem")};
    line-height: 1.5;
    ${(props) => props.upperCase && `text-transform:uppercase;`}
    ${(props) => props.whiteSpace && `white-space:${props.whiteSpace};`}

    &:focus {
        outline: none;

        box-shadow: ${(props) =>
          props.variant === "solid" && `${get("shadows.formControlFocus")}`};
    }

        ${(props) =>
          props.disabled &&
          `
      background-color: #FBFBFB
      box-shadow: ${get("shadows.small")}
    `}

    ${FLEX}
    ${COMMON};
    ${LAYOUT}
    ${BORDER}
    ${sizeVariants}

    ${(props) => props.error && `border: 1px solid #ff3f34`}

`;

export const FieldValue = styled(Text)`
    display: inline-block;
    box-sizing: border-box;
    background-color: transparent;
    border-bottom-width: thin;
    borderColor: #6e6e6e;
    box-shadow: none;
    px: 2;
    min-width: 200px;
    width: 100%;

    font-weight: 500;
    font-size: inherit;
    color: inherit;
    vertical-align: middle;
    opacity: 1;

    padding: ${(props) =>
      props.inline
        ? "0.25rem 0.25rem 0.2rem 0.75rem"
        : "0.325rem 0.25rem 0.3rem 0.75rem"};
    padding: ${(props) => props.disabled && "0px"}

    margin-top: ${(props) => (props.inline ? 0 : "0.125rem")};
    margin-bottom: ${(props) => (props.inline ? "0.5rem" : "0.125rem")};
    line-height: 1.5;
    ${(props) => props.upperCase && `text-transform:uppercase;`}


    &:focus {
        outline: none;
        box-shadow: ${(props) =>
          props.variant === "solid" && `${get("shadows.formControlFocus")}`};
    }

        ${(props) =>
          props.disabled &&
          `
      background-color: #FBFBFB
      box-shadow: ${get("shadows.small")}
    `}

    ${FLEX}
    ${COMMON};
    ${LAYOUT}
    ${BORDER}
    ${sizeVariants}

    ${(props) => props.error && `border: 1px solid #ff3f34`}

`;

Input.defaultProps = {
  variant: "solid",
};

export default Input;
