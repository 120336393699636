import {
  all,
  call,
  fork,
  put,
  takeEvery,
  takeLatest,
} from "redux-saga/effects";
import {
  searchPatientSuccess,
  searchPatientFail,
  fetchVGStudyReportSuccess,
  fetchReportStatusSuccess,
  forceRefresHVGStudyReportSuccess,
} from "../../appRedux/actions/PatientAction.js";
import {
  SEARCH_PATIENTS,
  FETCH_VG_STUDY,
  REFRESH_VG_REPORT_STATUS,
  FORCE_REFRESH_VG_STUDY,
} from "../../constants/ActionTypes";
import { ajaxPostRequest, endpoints } from "./config";

export function* searchPatientInfo(data) {
  const pathname = data.payload.path || "";
  let url = endpoints.searchPatient;
  if (pathname.toLowerCase().includes("bvstudy"))
    url = endpoints.searchPatientBv;
  try {
    const result = yield call(ajaxPostRequest, url, data.payload.json);
    if (result.length > 0) yield put(searchPatientSuccess(result));
    else yield put(searchPatientFail());
  } catch (error) {
    console.log("Patient info not found error", error);
  }
}

export function* getPatientVGStudyReport(data) {
  let path = data.payload.path;
  let study = path.toLowerCase();
  let requestData = data.payload.Data.PatientIds;
  const history = data.payload.history;
  const hasGeneratePermissions = data.payload.hasGeneratePermissions;
  console.log(
    "hasGeneratePermissions*****",
    hasGeneratePermissions,
    data.payload
  );

  let url = study.includes("pavog")
    ? endpoints.refreshReportStatus
    : endpoints.refreshReportStatusBv;

  if (hasGeneratePermissions) {
    url = study.includes("pavog")
      ? endpoints.fetchVgStudyReport
      : endpoints.fetchBvStudyReport;
    requestData = data.payload.Data;
  }
  try {
    const result = yield call(ajaxPostRequest, url, requestData);
    console.log("result", result);

    if (hasGeneratePermissions) yield put(fetchVGStudyReportSuccess(result));
    else yield put(fetchReportStatusSuccess(result));

    history.push({
      pathname: path + `/search-results`,
    });
  } catch (error) {
    console.log("Patient VGStudy not found error", error);
  }
}

export function* forceRefreshPatientVGStudyReport(data) {
  //here the payload data changes from getPatientVGStudyReport payload
  const path = data.payload.path.toLowerCase();
  const url = path.includes("pavog")
    ? endpoints.fetchVgStudyReport
    : endpoints.fetchBvStudyReport;
  try {
    const result = yield call(ajaxPostRequest, url, data.payload.json);
    yield put(forceRefresHVGStudyReportSuccess(result));
  } catch (error) {
    console.log("Patient VGStudy not found error", error);
  }
}

export function* getVGStudyReportStatus(data) {
  const path = data.payload.path.toLowerCase();
  const url = path.includes("pavog")
    ? endpoints.refreshReportStatus
    : endpoints.refreshReportStatusBv;
  try {
    const result = yield call(ajaxPostRequest, url, data.payload.json);
    yield put(fetchReportStatusSuccess(result));
  } catch (error) {
    console.log("Patient VGStudy not found error", error);
  }
}

export function* searchPatient() {
  yield takeLatest(SEARCH_PATIENTS, searchPatientInfo);
}
export function* patientVGStudyReport() {
  yield takeLatest(FETCH_VG_STUDY, getPatientVGStudyReport);
}

export function* refreshVGStudyReport() {
  yield takeLatest(REFRESH_VG_REPORT_STATUS, getVGStudyReportStatus);
}

export function* forceRefreshVGStudyReport() {
  yield takeLatest(FORCE_REFRESH_VG_STUDY, forceRefreshPatientVGStudyReport);
}
const patientSagas = function* rootSaga() {
  yield all([
    fork(searchPatient),
    fork(patientVGStudyReport),
    fork(refreshVGStudyReport),
    fork(forceRefreshVGStudyReport),
  ]);
};
export default patientSagas;
