//General
export const SUCCESS_BGCOLOR = "#27ae60";
export const FAILED_BGCOLOR = "#c0392b";

export const SHOW_MESSAGE = "SHOW_MESSAGE";
export const HIDE_MESSAGE = "HIDE_MESSAGE";

// auth
export const AUTH_USER = "AUTH_USER";
export const AUTH_USER_SUCCESS = "AUTH_USER_SUCCESS";
export const AUTH_USER_FAILED = "AUTH_USER_FAILED";
export const LOGGED_IN_STATUS_CHANGED = "LOGGED_IN_STATUS_CHANGED";
export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const LOGOUT = "LOGOUT";
export const LOGOUT_USER = "LOGOUT_USER";
export const USER_ROLE_PERMISSIONS = "USER_ROLE_PERMISSIONS";
export const LOGOUT_USER_SUCCESS = "LOGOUT_USER_SUCCESS";
export const LOGOUT_USER_FAILED = "LOGOUT_USER_FAILED";

export const LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS";
export const LOGIN_USER_FAILED = "LOGIN_USER_FAILED";
export const LOGIN_USER = "LOGIN_USER";
export const IDENTITY_UPDATED = "IDENTITY_UPDATED";
export const AUTH_FORM_UPDATE = "AUTH_FORM_UPDATE";

//notification
export const ADD_TOAST = "ADD_TOAST";
export const REMOVE_TOAST = "REMOVE_TOAST";
export const QUEUE_REMOVE = "QUEUE_REMOVE";

// search patient
export const SEARCH_PATIENTS = "SEARCH_PATIENTS";
export const SEARCH_PATIENTS_SUCCESS = "SEARCH_PATIENTS_SUCCESS";
export const SEARCH_PATIENTS_FAIL = "SEARCH_PATIENTS_FAIL";
export const FETCH_VG_STUDY = "FETCH_VG_STUDY";
export const FETCH_VG_STUDY_SUCCESS = "FETCH_VG_STUDY_SUCCESS";
export const REFRESH_VG_REPORT_STATUS = "REFRESH_VG_REPORT_STATUS";
export const REFRESH_VG_REPORT_STATUS_SUCCESS =
  "REFRESH_VG_REPORT_STATUS_SUCCESS";
export const FORCE_REFRESH_VG_STUDY = "FORCE_REFRESH_VG_STUDY";
export const FORCE_REFRESH_VG_STUDY_SUCCESS = "FORCE_REFRESH_VG_STUDY_SUCCESS";

export const ReportStatusType = {
  0: "Unknown",
  1: "Queued",
  2: "InProgress",
  3: "Completed",
  4: "ReadyToDownload",
  5: "Failed",
  6: "ReportNotFound",
};
