import React from "react";
import { View } from "react-native";
import { Route, Switch } from "./router";
import withTitle from "../components/pagetitle/withTitle";

import SearchPage from "../containers/SearchPage";
import VGStudyReportsPage from "../containers/VGStudyReportsPage";
import HomePage from "../components/HomePage";
// My-Task screens of Different system

class Routes extends React.PureComponent {
  render() {
    const { match } = this.props;

    return (
      <View>
        <Switch>
          <Route
            exact
            path="/search"
            component={withTitle({
              component: SearchPage,
              title: "Search",
              displayBreadCrumb: false,
            })}
          />

          <Route
            exact
            path="/PaVog"
            component={withTitle({
              component: SearchPage,
              title: "VGStudyReports",
              displayBreadCrumb: false,
            })}
          />
          <Route
            exact
            path="/PaVog/search-results"
            component={withTitle({
              component: VGStudyReportsPage,
              title: "VGStudyReports",
              displayBreadCrumb: false,
            })}
          />

          <Route
            exact
            path="/BvStudy"
            component={withTitle({
              component: SearchPage,
              title: "BvStudy",
              displayBreadCrumb: false,
            })}
          />
          <Route
            exact
            path="/BvStudy/search-results"
            component={withTitle({
              component: VGStudyReportsPage,
              title: "BvStudy",
              displayBreadCrumb: false,
            })}
          />

          <Route
            exact
            path="/"
            component={withTitle({
              component: HomePage,
              title: "Vyaire IRIS",
            })}
          />
        </Switch>
      </View>
    );
  }
}

export default Routes;
