import { Feather } from "@expo/vector-icons";
import React, { useState, useEffect, useRef } from "react";
import {
  View,
  Image,
  StyleSheet,
  TextInput,
  TouchableOpacity,
  Text,
  Pressable,
} from "react-native";
import { useHistory } from "react-router-dom";
import { Link, useLocation } from "../../navigation/router";
import { Colors } from "../../theme/Index";
import MenuDivider from "../MenuDivider";
import MenuItem from "../MenuItem";
import PopUpMenu from "../PopUpMenu";
import VyaireLogo from "../VyaireLogo";
import Flex from "../common/Flex";
import NavLink from "./NavLink";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import {
  FontAwesome,
  MaterialCommunityIcons,
  MaterialIcons,
  AntDesign,
} from "@expo/vector-icons";
import BreadCrumbs from "../../components/BreadCrumbs";

class DropDownMenu extends React.Component {
  _menu = null;

  constructor(props) {
    super(props);
    this.state = {
      showing: false,
    };
  }

  setMenuRef = (ref) => {
    this._menu = ref;
    this._menu.hide();
  };

  hideMenu = () => {
    this._menu.hide();
  };
  onLogout = () => {
    this.hideMenu();
    this.props.onLogout();
  };

  showMenu = () => {
    if (this.state.showing === true) {
      this._menu.hide();
    } else {
      this._menu.show();
    }

    this.setState({ showing: !this.state.showing });
  };

  render() {
    return (
      <View style={styles.dropDownMainContainer}>
        <PopUpMenu
          onHidden={() => this.setState({ showing: false })}
          ref={this.setMenuRef}
          button={
            <TouchableOpacity onPress={this.showMenu}>
              <FontAwesome
                name="user-circle-o"
                color={Colors.irisPurple}
                backgroundColor="#FFFFFF"
                style={styles.iconStyle}
                size={23}
              />
            </TouchableOpacity>
          }
        >
          <MenuItem onPress={this.hideMenu}>
            <View style={styles.settingsView}>
              <Text style={styles.settingViewText}>
                {this.props.currentUser.username}
              </Text>
            </View>
          </MenuItem>
          <MenuDivider />
          <MenuItem onPress={this.onLogout}>
            <View style={styles.settingsView}>
              <Feather
                style={styles.featherStyle}
                name="log-out"
                size={13}
                color="#1e272e"
              />
              <Text style={styles.logoutText}>Logout</Text>
            </View>
          </MenuItem>
        </PopUpMenu>
      </View>
    );
  }
}

class Signout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showing: false,
    };
  }
  onLogout = () => {
    this.props.onLogout();
  };

  render() {
    return (
      <Flex alignCenter>
        <View style={styles.settingsView}>
          <Text style={styles.settingViewText}>
            {this.props.currentUser.username}
          </Text>
        </View>
        <View>
          <Pressable onPress={this.onLogout}>
            <AntDesign
              name="logout"
              color={Colors.brickRed}
              backgroundColor="#FFFFFF"
              style={styles.iconStyle}
              size={23}
            />
          </Pressable>
        </View>
      </Flex>
    );
  }
}
function useComponentVisible(initialIsVisible) {
  const [open, setOpen] = useState(initialIsVisible);
  const ref = useRef(null);

  const handleHideDropdown = (event) => {
    if (event.key === "Escape") {
      setOpen(false);
    }
  };

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleHideDropdown, true);
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("keydown", handleHideDropdown, true);
      document.removeEventListener("click", handleClickOutside, true);
    };
  });

  return { ref, open, setOpen };
}

export default function Header({ currentUser, onLogout }) {
  const location = useLocation();
  // const { ref, open, setOpen } = useComponentVisible(false);

  // const [open, setOpen] = React.useState(false);
  const history = useHistory();

  return (
    <View style={styles.container}>
      <Flex alignCenter>
        <View style={{ paddingLeft: 10 }}>
          <Link to="/">
            <FontAwesome
              name="home"
              color={Colors.irisPurple}
              backgroundColor="#FFFFFF"
              style={styles.iconStyle}
              size={23}
            />
          </Link>
        </View>
        <BreadCrumbs
          title={"Search results screen2"}
          currentPath={location.pathname}
          links={location.pathname.split("/")}
          blacklist={[]}
        />
      </Flex>

      <Flex justifyEnd alignCenter>
        <></>

        <Signout currentUser={currentUser} onLogout={onLogout} />
      </Flex>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    minHeight: 50,
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "row",
    backgroundColor: Colors.white,
    borderBottomColor: Colors.irisPurple,
    borderBottomWidth: 1,
    shadowColor: Colors.dark,
    color: Colors.irisPurple,
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.22,
    shadowRadius: 4,

    elevation: 4,
  },
  logoContainer: {
    paddingTop: 5,
  },
  userIcon: {
    width: 58,
    height: 58,
    marginTop: 5,
    marginRight: 5,
    backgroundColor: Colors.white,
  },
  searchIcon: {
    width: 22.5,
    height: 22.5,
    marginTop: 5,
    marginRight: 15,
    backgroundColor: Colors.white,
  },
  menuIcon: {
    marginHorizontal: 25,
  },

  gearIcon: {
    width: 43,
    height: 43,
    marginTop: 2.5,
    marginRight: 5,
    backgroundColor: "#FFFFFF",
  },

  mText: {
    fontSize: 34,
    fontWeight: "400",
    marginLeft: 5,
    paddingBottom: 5,
    color: Colors.lightBlue,
  },
  settingsView: {
    flex: 1,
    flexBasis: "auto",
    flexDirection: "row",
    alignItems: "center",
    minWidth: 150,
    justifyContent: "flex-end",
  },
  iconStyle: {
    padding: 10,
    paddingVertical: 4,
  },
  settingViewText: {
    color: Colors.irisPurple,
    fontWeight: "bold",
    fontStyle: "italic",
  },
  dropDownMainContainer: {
    alignItems: "center",
    justifyContent: "center",
    marginRight: 15,
    zIndex: 4,
  },
  featherStyle: {
    marginLeft: 8,
    marginRight: 15,
    borderRadius: 25,
    borderColor: "grey",
    borderWidth: 1,
    padding: 5,
  },
  logoutView: {
    flex: 1,
    flexBasis: "auto",
    flexDirection: "row",
    alignItems: "center",
  },
  logoutText: {
    color: "#e74c3c",
  },
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: "white",
    fontFamily: "Poppins",
    fontSize: 16,
    fontWeight: "bold",
    color: "#12243F",
  },
  nested: {
    paddingLeft: 4,
  },
  rootFont: {
    fontFamily: "Poppins",
    fontSize: 16,
    fontWeight: "bold",
    color: "#12243F",
  },
});
