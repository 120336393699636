const Colors = {
    background: '#FAFAFA',
    white: '#fff',
    clear: 'rgba(0,0,0,0)',
    transparent: 'rgba(0,0,0,0)',
    silver: '#cfcfcf',
    cloud: 'rgba(200,200,200, 0.35)',
    dark: '#000000',
    windowTint: 'rgba(0, 0, 0, 0.4)',
    panther: '#161616',
    charcoal: '#595959',
    coal: '#2d2d2d',
    darkBlue: '#12243F',
    primary: '#1D4289',
    lightBlue: '#10206C',
    lightGreen: '#02b902a3',
    lightRed: '#ff0000bf',
    lightYellow: '#ffff00a8',
    lightGrey: '#808080a6',
    vyaireBlue: '#201751',
    cobaltBlue: '#2C2B94',
    brickRed: '#B2292E',
    vyaireRed: '#EA0029',
    royalBlue: '#10206C',
    deepBlue: '#130F32',
    deepGreen: '#0D5351',
    slateGrey: '#717173',
    irisPurple: '#10206C', //royalblue
    // irisPurple: '#76256C',
    seaGreen: '#00A9A5',
    eightyBlack: '#58595B',
    fourtyBlack: '#A7A9AC',
    fiveBlack: '#F1F2F2',
    skyBlue: '#C6D9F1',
    tenPurple: '#E6E0EC',
    twentyPurple: '#d6e8ff', //light blue
    // twentyPurple: '#CCC1DA',
    thirtyPurple: '#B3A2C7',
};

export default Colors;
