import React, { useState, Component } from "react";
import { StyleSheet, View, Image, ActivityIndicator } from "react-native";
import { connect } from "react-redux";
import { ThemeProvider } from "styled-components/native";
import PageLoading from "./components/PageLoading";
import { PrivateRoute } from "./components/PrivateRoute";
import Login from "./containers/Login.jsx";
import { Router, Route, Switch, Redirect, Link } from "./navigation/router";
import { theme } from "./theme/Index";
import SourceApp from "./SourceApp";
import { authUser } from "./appRedux/actions/Auth";
import { Auth, Hub, Cache, Amplify } from "aws-amplify";
import config from "./containers/amplify-config";
Amplify.configure(config);

const ProppedRoute = ({ render: C, props: childProps, ...rest }) => (
  <Route {...rest} render={(rProps) => <C {...rProps} {...childProps} />} />
);

const MainRoutes = ({ childProps }) => (
  <Switch>
    <ProppedRoute exact path="/login" render={Login} props={childProps} />
    <PrivateRoute path="/" render={SourceApp} props={childProps} />
  </Switch>
);

class Root extends React.Component {
  constructor(props) {
    super(props);

    Hub.listen("auth", (data) => {
      const { payload } = data;
      if (data.payload.event === "signIn") {
        this.props.authUser();
      }
    });

    this.state = {
      fontLoaded: false,
      redirect: false,
    };

    this._bootstrapAsync();
  }

  // eslint-disable-next-line no-underscore-dangle
  async _bootstrapAsync() {
    const federatedInfo = await Cache.getItem("federatedInfo");
    this.props.authUser();
  }

  render() {
    const { loggedIn, loading } = this.props;

    const childProps = {
      isLoggedIn: loggedIn,
      onUserSignIn: this.props.authUser,
      currentUser: this.props.user,
      loading: this.props.loading,
    };

    // if (!this.state.fontLoaded) return <PageLoading />;

    if (loading === true || loggedIn === null) {
      return (
        <View
          style={{
            flex: 1,
            flexBasis: "auto",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <ActivityIndicator />
        </View>
      );
    }

    return (
      <ThemeProvider theme={theme}>
        <Router hashType="noslash">
          <Route path="/">{!loggedIn && <Redirect to="/login" />}</Route>
          <MainRoutes childProps={childProps} />
        </Router>
      </ThemeProvider>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loggedIn: state.auth.loggedIn,
    user: state.auth.user,
    loggedOut: state.auth.loggedOut,
  };
};

export default connect(mapStateToProps, {
  authUser,
})(Root);
