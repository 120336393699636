import {
  SEARCH_PATIENTS,
  SEARCH_PATIENTS_SUCCESS,
  SEARCH_PATIENTS_FAIL,
  FETCH_VG_STUDY,
  FETCH_VG_STUDY_SUCCESS,
  REFRESH_VG_REPORT_STATUS,
  REFRESH_VG_REPORT_STATUS_SUCCESS,
  FORCE_REFRESH_VG_STUDY,
  FORCE_REFRESH_VG_STUDY_SUCCESS,
} from "../../constants/ActionTypes";

export const searchPatient = (patient) => {
  return {
    type: SEARCH_PATIENTS,
    payload: patient,
  };
};
export const searchPatientSuccess = (data) => {
  return {
    type: SEARCH_PATIENTS_SUCCESS,
    payload: data,
  };
};

export const searchPatientFail = () => {
  return {
    type: SEARCH_PATIENTS_FAIL,
  };
};

export const fetchVGStudyReport = (data) => {
  return {
    type: FETCH_VG_STUDY,
    payload: data,
  };
};

export const fetchVGStudyReportSuccess = (data) => {
  return {
    type: FETCH_VG_STUDY_SUCCESS,
    payload: data,
  };
};

export const fetchReportStatus = (data) => {
  return {
    type: REFRESH_VG_REPORT_STATUS,
    payload: data,
  };
};

export const fetchReportStatusSuccess = (data) => {
  return {
    type: REFRESH_VG_REPORT_STATUS_SUCCESS,
    payload: data,
  };
};

export const forceRefreshVGStudyReport = (data) => {
  return {
    type: FORCE_REFRESH_VG_STUDY,
    payload: data,
  };
};

export const forceRefresHVGStudyReportSuccess = (data) => {
  return {
    type: FORCE_REFRESH_VG_STUDY_SUCCESS,
    payload: data,
  };
};
